/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 24,
    kname: 'name',
    itemProps: {
      label: '姓名：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 4 } } },



  {
    span: 24,
    kname: 'mobile',
    itemProps: {
      label: '电话：',
      rules: [genRules('require', '请输入手机号！'), genRules('telephone')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 11 } } },



  {
    span: 24,
    kname: 'email',
    itemProps: {
      label: '邮箱：',
      rules: [genRules('require'), genRules('email')] },

    component: {
      name: 'el-input' } },


  {
    kname: 'remark',
    itemProps: {
      label: '备注说明：' },

    component: {
      name: 'el-input',
      props: {
        type: 'textarea',
        maxlength: 50 } } }];});





// form 默认值
export var formData = function formData() {return {
    //li: [{},{},{},{},{},{},{},{}]
  };};